import {useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom';
import {GrowthBook, GrowthBookProvider} from '@growthbook/growthbook-react';
import theme from './theme';
import SummarizationView from './views/SummarizationView';
import BaseView from './views/BaseView';
import HowView from './views/HowView';
import PolicyView from './views/PolicyView';
import AboutAndContactView from './views/AboutAndContactView';
import PrivacyView from './views/PrivacyView';
import * as amplitude from '@amplitude/analytics-browser';
import {AMPLITUDE_KEY, DEV_MODE, GROWTH_BOOK_CLIENT_KEY} from './globals';
import NotFoundPage from './views/NotFoundPage';
import RegionWrapper from './components/RegionWrapper';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/nl" replace />,
  },
  {
    path: '/:region',
    element: <RegionWrapper />,
    children: [
      {
        path: '',
        element: <SummarizationView />,
      },
      {
        path: 'content',
        element: <BaseView />,
        children: [
          {path: 'werking', element: <HowView />},
          {path: 'beleid', element: <PolicyView />},
          {path: 'over', element: <AboutAndContactView />},
          {path: 'privacy', element: <PrivacyView />},
          {path: '*', element: <NotFoundPage />},
        ],
      },
      {
        path: '*', element: <NotFoundPage />,
      },
    ],
  },
]);


const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTH_BOOK_CLIENT_KEY,
  enableDevMode: DEV_MODE,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    amplitude.track(
      'Experiment Viewed',
      {
        experimentId: experiment.key,
        variantId: result.key,
        variantName: result.name,
      });
  },
});

export default function App() {
  growthbook.loadFeatures();
  useEffect(() => {
    amplitude.init(AMPLITUDE_KEY, {
      defaultTracking: true,
      serverZone: 'EU',
      identityStorage: 'none',
      trackingOptions: {
        ipAddress: false,
      },
    }).promise.then((result) => {
      growthbook.setAttributes({
        deviceId: amplitude.getDeviceId(),
      });
    });
    amplitude.track('App opened');
  }, []);

  useEffect(() => {
    // Load features asynchronously when the app renders

  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GrowthBookProvider growthbook={growthbook}>
        <CssBaseline />
        <RouterProvider router={router} />
      </GrowthBookProvider>
    </ThemeProvider>
  );
}
