import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Divider} from '@mui/material';
import {useTheme} from '@emotion/react';

export default function ControlledAccordion({paragraphs, sx, expanded, handleExpansion}) {
  const theme = useTheme();

  return (
    <Box sx={sx}>
      {paragraphs?.map((p) => {
        return <Accordion
          key={p.uuid}
          expanded={expanded === p.uuid}
          onChange={() => handleExpansion(p.uuid)}
          sx={{boxShadow: 'none'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="secondary" />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center">
              <img
                src={p.party?.logo}
                style={{maxHeight: '40px', maxWidth: '40px'}}
              />
              <Box
                sx={{
                  mx: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}>
                <Typography
                  sx={{
                    color: 'text.dark',
                    lineHeight: '1em',
                    mb: '10px',
                  }}
                  fontSize={14}
                  fontWeight="bold">
                  {p.chapter}
                </Typography>
                <Typography
                  sx={{
                    color: 'text.main',
                    lineHeight: '1em',
                    m: 0,
                  }}
                  fontSize={11}>{p.title}
                </Typography>
              </Box>
            </Box>
            {p.relevance &&
              <Box
                sx={{
                  ml: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'right',
                }}>
                <Typography
                  sx={{
                    color: theme.palette.secondary.main,
                    lineHeight: '1em',
                    m: 0,
                  }}
                  fontSize={14}>
                  {Math.round(p.relevance * 100)}%
                </Typography>
              </Box>
            }
          </AccordionSummary>
          <AccordionDetails>
            <Divider orientation="horizontal" />
            <Typography
              fontSize={14}
              dangerouslySetInnerHTML={{__html: p.formatted_text}}>
            </Typography>
          </AccordionDetails>
        </Accordion>;
      })}
    </Box>
  );
}
