import Box from '@mui/material/Box';
import {Grid} from '@mui/material';
import * as React from 'react';
import _ from 'lodash';

import PartyButton from './PartyButton';

export default function PartyButtons({
  parties,
  isProcessing,
  onClick,
  currentParties,
  maxSelectedParties = 3,
  sx,
}) {
  const partyLimitReached = currentParties?.length == maxSelectedParties;

  const getToolTipForParty = (party, isSelected) => {
    if (partyLimitReached && !isSelected) {
      return `Je kan maximaal ${maxSelectedParties} partijen vergelijken`;
    } else {
      return party.name;
    }
  };

  return (
    <Box
      sx={{
        ...{
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }, ...sx,
      }}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {parties?.map((party) => {
          const isSelected = _.includes(
            currentParties.map((p) => p.uuid),
            party.uuid,
          );

          const isDisabled = (isProcessing || partyLimitReached) && !isSelected;

          return (
            <Grid
              item
              key={party.uuid}
              sx={{
                flex: '1 1 auto',
                minWidth: {
                  xs: 80,
                  sm: 100,

                },
                maxWidth: {
                  xs: 80,
                  sm: 100,
                },
              }}
            >
              <PartyButton
                disabled={isDisabled}
                tooltip={getToolTipForParty(party, isSelected)}
                onClick={() => onClick(party)}
                image={party.logo}
                selected={isSelected}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
