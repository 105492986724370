import * as React from 'react';
import Box from '@mui/material/Box';
import ElectionSelector from './ElectionSelector';

export default function SummaryActions({
  sx,
  party,
  election,
  setElection,
}) {
  return <Box
    sx={{
      ...sx,
      display: 'flex',
      justifyContent: 'space-between',
    }}>
    <ElectionSelector
      availableElections={party?.participates_in?.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      }).reverse()}
      election={election}
      setElection={setElection}></ElectionSelector>
  </Box>;
}
