import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Tips from './Tips';
import * as amplitude from '@amplitude/analytics-browser';
import Box from '@mui/material/Box';
import {IconButton, Divider, AlertTitle, Alert, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import VerifiedIcon from '@mui/icons-material/Verified';
import Link from '@mui/material/Link';
import ControlledAccordion from './ControlledAccordion';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {DONATION_LINK} from '../globals';


export function ExternallyControlledDialog({
  sx,
  title,
  children,
  open,
  onClose,
}) {
  return (
    <Box style={sx}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {/* TODO: This now created errors */}
          <Typography color="secondary" variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export function TimedDialog({
  timeout = 1000,
  sx,
  title,
  children,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, timeout);
    return () => clearTimeout(timer);
  }, []);

  return <>
    <ExternallyControlledDialog
      sx={sx}
      title={title}
      open={open}
      onClose={() => setOpen(false)}>{children}
    </ExternallyControlledDialog>
  </>;
}


export function BaseDialog({
  openElement,
  sx,
  title,
  closeCallback,
  children,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeCallback?.();
  };

  return (
    <Box style={sx}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {/* TODO: This now created errors */}
          <Typography color="secondary" variant="h6">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {React.cloneElement(
        openElement,
        {onClick: handleClickOpen},
      )}
    </Box>
  );
}

export function TipsDialog({
  openElement,
  sx,
}) {
  return (
    <BaseDialog
      openElement={openElement}
      sx={sx}
      title="Tips voor het beste resultaat">
      <Tips />
    </BaseDialog>
  );
}

export function AboutDialog({
  openElement,
  sx,
}) {
  return (
    <BaseDialog
      openElement={openElement}
      sx={sx}
      title="Hoe werkt OpenVerkiezingen.nl?">
      <Typography variant="body2" component="p" mb={3}>
        OpenVerkiezingen.nl heeft als doel verkiezinsprogramma&apos;s beter toegankelijk te maken.
        Door het gebruik van kunstmatige intelligentie biedt OpenVerkiezingenNL de mogelijkheid om
        zelf een stelling in te voeren en in een korte, automatisch gegenereerde samenvatting terug
        te krijgen wat een partijprogramma over die stelling zegt.
      </Typography>
      <Typography variant="body2" component="p" mb={3}>
        Om dit te doen heeft OpenVerkiezingen.nl de <b>programma&apos;s van de grootste landelijke
          partijen gedigitaliseerd</b>.
        Een tweelaags kunstmatige inlligentie systeem dat gebruik maakt
        van dezelfde technologie als ChatGPT gecombineerd met die van de Amsterdamse
        startup <Link color="secondary" href="https://weaviate.io/">
          Weaviate
        </Link> wordt gebruikt om automatisch een standpunt te vinden op basis van de
        tekst van het verkiezingsprogramma.
      </Typography>
      <Divider></Divider>
      <Typography color="secondary" variant="h6" mt={3}>Tips voor het beste resultaat</Typography>
      <Tips />
    </BaseDialog>
  );
}


export function AccountabilityWhenNotCheckedDialog({
  openElement,
  sx,
  party,
  paragraphs,
  expanded,
  handleExpansion,
}) {
  return (
    <BaseDialog title="Verantwoording" openElement={openElement} sx={sx} closeCallback={() => {
      handleExpansion(false);
    }}>
      <Typography variant="body2" component="p">
        We hebben dit resultaat gebaseerd op het programma van
        &nbsp;{party.slug}. Uit dit programma hebben we de volgende bronnen gebruikt
        <ControlledAccordion
          sx={{mt: 3}}
          paragraphs={paragraphs}
          expanded={expanded}
          handleExpansion={handleExpansion}
        ></ControlledAccordion>
      </Typography>
      <Alert severity="warning" sx={{mt: 3}} icon={<WarningAmberIcon />}>
        <AlertTitle>Let op!</AlertTitle>
        <Typography variant="body2" component="p">
          Dit resultaat is gegenereerd door <strong>Kunstmatige Intelligentie</strong>.
          Dit systeem is niet perfect en kan in sommige gevallen onverwacht gedrag vertonen.
          Kijk daarom altijd goed of de gevonden en geselecteerde paragraaf bij jouw stelling past
          &nbsp;en of het antwoord redelijk klinkt. We werken er hard aan het systeem te blijven
          &nbsp;verbeteren.
        </Typography>
      </Alert>
    </BaseDialog>
  );
}


export function AccountabilityWhenCheckedDialog({
  openElement,
  sx,
  party,
  paragraphs,
  expanded,
  handleExpansion,
}) {
  return (
    <BaseDialog title="Verantwoording" openElement={openElement} sx={sx} closeCallback={() => {
      handleExpansion(false);
    }}>
      <Typography variant="body2" component="p">
        Dit resultaat is gebasseerd op het programma van
        &nbsp;{party.slug}. Uit dit programma hebben we de volgende bronnen gebruikt
        <ControlledAccordion
          sx={{mt: 3}}
          paragraphs={paragraphs}
          expanded={expanded}
          handleExpansion={handleExpansion}
        >
        </ControlledAccordion>
      </Typography>
      <Alert severity="success" sx={{mt: 3}} icon={<VerifiedIcon />}>
        <AlertTitle>Deze samenvatting is extra door AI gecontroleerd.</AlertTitle>
        <Typography variant="body2" component="p">
          Alle resultaten op OpenVerkiezingenNL worden gegenereerd met
          {' '}<b>Kunstmatige Intelligentie</b>.
          Deze technologie is niet perfect en de resultaten kunnen dus fouten bevatten.
          Een speciaal AI algoritme heeft daarna extra gechecked of dit antwoord in lijn
          {' '}is met de bronnen.
          Hiermee zijn fouten niet uitgesloten, maar is de kans erop wel kleiner.
          Er is hier alleen gekeken naar de drie bovenstaande bronnen en er is geen contact
          {' '}geweest met de partij zelf.
        </Typography>
      </Alert>
    </BaseDialog>
  );
}

export function AccountabilityDialog({
  openElement,
  sx,
  party,
  paragraphs,
  areFactsGrounded,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleExpansion = (panel) => {
    if (!(panel === expanded)) {
      amplitude.track(
        'Source dialog panel clicked.',
        {panelID: panel},
      );
    }
    setExpanded(panel === expanded ? false : panel);
  };

  if (areFactsGrounded) {
    return (
      <AccountabilityWhenCheckedDialog
        openElement={openElement}
        sx={sx}
        party={party}
        paragraphs={paragraphs}
        expanded={expanded}
        handleExpansion={handleExpansion}
      />
    );
  } else {
    return (
      <AccountabilityWhenNotCheckedDialog
        openElement={openElement}
        sx={sx}
        party={party}
        paragraphs={paragraphs}
        expanded={expanded}
        handleExpansion={handleExpansion}
      />
    );
  }
}

function DonationDialogContents() {
  return <>
    <Typography variant="body2" component="p" mb={3}>
      Stichting Open Politiek richt zich erop om de Nederlandse democratie toegankelijker en
      transparanter te maken. Als onderdeel van deze missie
      hebben we <b>OpenVerkiezingen.nl</b> ontwikkeld. De ontwikkeling van OpenVerkiezingen.nl
      gebeurt  volledig op basis van <b>giften en donaties</b>. Je zou ons enorm helpen met een
      bijdrage om zo door te kunnen gaan met onze missie. Met een bijdrage van &euro; 10,- kunnen
      wij komend jaar bijvoorbeeld al 1000 vragen over kamermoties beantwoorden.
    </Typography>
    <Divider></Divider>
    <Grid container spacing={1} sx={{mt: 1}}>
      <Grid item xs={6} sm={3} sx={{textAlign: 'center'}}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{width: '100px'}}
          component={Link}
          target="_blank"
          href={`${DONATION_LINK}/5.00`}>&euro; 5,-</Button>
      </Grid>
      <Grid item xs={6} sm={3} sx={{textAlign: 'center'}}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{width: '100px'}}
          component={Link}
          target="_blank"
          href={`${DONATION_LINK}/10.00`}>&euro; 10,-</Button>
      </Grid>
      <Grid item xs={6} sm={3} sx={{textAlign: 'center'}}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{width: '100px'}}
          component={Link}
          target="_blank"
          href={`${DONATION_LINK}/50.00`}>&euro; 50,-</Button>
      </Grid>
      <Grid item xs={6} sm={3} sx={{textAlign: 'center'}}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{width: '100px'}}
          component={Link}
          target="_blank"
          href={`${DONATION_LINK}`}>&euro; ...,-</Button>
      </Grid>
    </Grid>
  </>;
}

export function TimedDonationDialog({
  timeout,
  sx,
}) {
  return <TimedDialog
    sx={sx}
    timeout={timeout}
    title="Mogen we je wat vragen?">
    <DonationDialogContents />
  </TimedDialog>;
}

export function DonationDialog({
  openElement,
  sx,
}) {
  return <>
    <BaseDialog
      title="Doneer aan Stichting Open Politiek"
      openElement={openElement}
      sx={sx}>
      <DonationDialogContents />
    </BaseDialog>
  </>;
}
