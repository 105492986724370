import {Outlet, useParams} from 'react-router-dom';
import {useFetchGet, useSearchParamsState} from '../hooks';
import NotFoundPage from '../views/NotFoundPage';
import {BASE_URI} from '../globals';

const RegionWrapper = () => {
  const {region} = useParams();
  const {data: elections} = useFetchGet(`${BASE_URI}/region/${region}/election/`, []);
  // The default election is the one with the latest date.
  const [defaultElection] = useSearchParamsState('election', elections?.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  })?.[0]);
  const {data: regionData} = useFetchGet(
    `${BASE_URI}/region/${region}/`, {slug: 'nl', name: 'Nationaal'},
  );

  if (!regionData) {
    return <NotFoundPage missingRegionData />;
  }

  return <Outlet context={{election: defaultElection}} />;
};

export default RegionWrapper;
