import Typography from '@mui/material/Typography';
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ControlledAccordion from './ControlledAccordion';
import SummaryActions from './SummaryActions';

export default function DefaultSummary({
  data,
  summary,
  generating,
  handleExpansion,
  expanded,
  party,
  election,
  setElection,
}) {
  return <>
    {summary && <Typography
      color="text.primary"
      variant="p"
      gutterBottom>
      {summary}
      {generating && <CircularProgress
        color="secondary"
        fontSize="6px"
        size="12px"
        sx={{marginLeft: '10px'}} />}
    </Typography>}
    <SummaryActions
      data={data}
      sx={{
        mt: 1,
        mb: 1,
      }}
      party={party}
      election={election || data?.election}
      setElection={setElection} />
    <ControlledAccordion
      sx={{
        mt: 1,
        mb: 1,
      }}
      paragraphs={data?.paragraphs}
      expanded={expanded}
      handleExpansion={handleExpansion}
    ></ControlledAccordion>
  </>;
}
