import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';

import theme from './theme';
import EmbeddedView from './embeddedViews/EmbeddedView';
import RegionWrapper from './components/RegionWrapper';
import NotFoundPage from './views/NotFoundPage';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/nl" replace />,
  },
  {
    path: '/:region',
    element: <RegionWrapper/>,
    children: [
      {path: '', element: <EmbeddedView />},
      {path: '*', element: <NotFoundPage />},
    ],
  },
]);

export default function Embedded() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}
