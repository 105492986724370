import {Chip} from '@mui/material';

const LogoChip = ({election, sx}) => (
  <>
    {election && <Chip
      label={election?.name || ''}
      sx={{
        height: '15px',
        marginTop: '-20px',
        backgroundColor: 'secondary.main',
        color: 'text.white',
        fontSize: '10px',
        fontWeight: 'bold',
        borderRadius: '8px',
        ...sx,
      }}
    />}
  </>
);

export default LogoChip;
