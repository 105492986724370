import {Alert, IconButton} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import VerifiedIcon from '@mui/icons-material/Verified';
import Box from '@mui/material/Box';
import * as React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import {AccountabilityDialog} from './dialogs';

export default function UsingAIWarning({
  party,
  paragraphs,
  summary,
  areFactsGrounded,
  generating,
  sx,
}) {
  return (
    <>
      {(generating || !summary || !paragraphs) ? null : (
        <Box sx={sx}>
          {areFactsGrounded &&
            <Alert
              color="success"
              icon={<VerifiedIcon />}
              action={<AccountabilityDialog
                areFactsGrounded={true}
                openElement={
                  <IconButton color="success"><InfoIcon /></IconButton>
                }
                party={party}
                paragraphs={paragraphs} />}>
              <b>
                Deze samenvatting is extra door AI gecontroleerd en lijkt lijn met de bronnen.
                Echter, pas altijd op: AI gegenereerde samenvattingen kunnen fouten bevatten.
              </b>
            </Alert>
          }
          {!areFactsGrounded &&
            <Alert
              color="warning"
              sx={{mt: 3}}
              icon={<WarningAmberIcon />}
              action={<AccountabilityDialog
                areFactsGrounded={false}
                openElement={
                  <IconButton color="warning"><HelpIcon /></IconButton>
                }
                party={party}
                paragraphs={paragraphs} />}>
              <b>Pas op, deze AI-samenvatting kan fouten bevatten!</b>
            </Alert>
          }
        </Box>
      )}
    </>
  );
}
