import {ButtonBase} from '@mui/material';
import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import {useState} from 'react';

export default function PartyButton({
  image,
  onClick,
  disabled,
  notAvailable,
  tooltip,
  selected,
  sx,
}) {
  const [hover, setHover] = useState(false);
  image = image || '/unkown.jpeg';

  const clipPath = 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)';

  return (
    <Tooltip title={tooltip} arrow enterTouchDelay={200}>
      <Box
        sx={{
          ...{
            backgroundColor: (t) => selected ? t.palette.secondary.main : '#cccccc',
            clipPath: clipPath,
            padding: selected ? '5px' : '1px', // This acts as the border
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          ...sx,
        }}>
        <ButtonBase
          sx={{
            display: 'flex',
            bgcolor: '#FFFFFF',
            clipPath: clipPath,
            width: hover ? '98%' : '100%',
            aspectRatio: '1 / 1',
            p: '18%',
          }}
          disabled={disabled}
          onClick={onClick}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <img
            src={image}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              width: 'auto',
              height: 'auto',
              filter: notAvailable ? 'grayscale(100%)' : null,
            }} />
        </ButtonBase>
      </Box>
    </Tooltip>
  );
}
