import {useTheme} from '@emotion/react';
import {Typography, Box} from '@mui/material';
import {Link, useParams} from 'react-router-dom';


const NotFoundPage = ({missingRegionData = false}) => {
  const theme = useTheme();
  const {region} = useParams();

  return (
    <>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: '15px',
        }}
      >
        <Typography variant="h1" color="primary" gutterBottom>
          404 - Pagina Niet Gevonden
        </Typography>
        <Typography variant="h2" color="secondary" gutterBottom>
          De pagina die je zoekt bestaat niet.
        </Typography>
        <Typography variant="body1" component="p">
          Ga {
            <Link
              style={{color: theme.palette.secondary.main}}
              to={missingRegionData ? '/' : `/${region}`}
            >
              terug
            </Link>
          } naar de homepage.
        </Typography>
      </Box>
    </>
  );
};

export default NotFoundPage;
