import Typography from '@mui/material/Typography';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import Box from '@mui/material/Box';
import ndjsonStream from 'can-ndjson-stream';
import * as amplitude from '@amplitude/analytics-browser';
import UsingAIWarning from './UsingAIWarning';
import {SUMMARIZER_URI} from '../globals';
import {Alert, IconButton} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import {useLocation} from 'react-router-dom';
import {shareSummary} from '../share';
import {ExternallyControlledDialog} from './dialogs';
import HelpIcon from '@mui/icons-material/Help';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import InformedPendingHandler from './InformedPendingHandler';
import DefaultSummary from './DefaultSummary';


function RelatedStatementInfoDialog({relatedStatement, originalStatement, sx, onRequestExact}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const onClickRequestExact = () => {
    setDialogOpen(false);
    onRequestExact();
  };
  return (
    <>
      <ExternallyControlledDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="Waarom doen we dit?">
        <Typography>
          Helaas is het erg kostbaar om AI samenvattingen te maken.
          Zowel qua kosten als vanuit een duurzaamheids-perspectief.
          Daarom hergebruiken we als het mogelijk is eerder berekende resultaten.
          Wil je het exacte resultaat berekenen voor jouw stelling: <b>{originalStatement}</b>?
          Gebruik dan de knop hieronder.</Typography>
        <Button
          sx={{mt: '15px'}}
          variant="contained"
          onClick={onClickRequestExact}
        >Bereken de exacte samenvatting</Button>
        <Alert color="primary"
          icon={<NewspaperIcon />}
          sx={{mt: 2}}>
          Gebruik jij OpenVerkiezingen.nl voor journalistieke doeleinden?
          Dan helpen wij jou graag met een versie waarin jij altijd direct antwoord krijgt op
          jouw originele vraag zonder door dit venster te moeten klikken. Stuur een email
          naar <Link color="secondary" href="mailto:info@openverkiezingen.nl">
            info@openverkiezingen.nl
          </Link> om onbeperkt toegang te krijgen.
        </Alert>
      </ExternallyControlledDialog>
      <Alert
        color="primary" sx={{...{mb: 1}, ...sx}}
        icon={<InfoIcon />}
        action={<IconButton
          color="primary"
          onClick={() => setDialogOpen(true)}>
          <HelpIcon />
        </IconButton>}>
        We tonen je het resultaat voor een vergelijkbare
        stelling: <b>{relatedStatement}</b>. Klik op het vraagteken hiernaast
        voor meer informatie.
      </Alert>
    </>

  );
}

function ShareButton({
  generating,
  summary,
  query,
  party,
}) {
  const location = useLocation();

  const button = <IconButton
    color="primary"
    sx={{position: 'absolute', bottom: '0px', right: '0px'}}
    onClick={() => {
      shareSummary(query, summary, party, location);
      amplitude.track('Share summary');
    }}>
    <ShareIcon /></IconButton>;

  return <>
    {!!navigator.share && !generating && summary && button}
  </>;
}


export default function AISummary({
  party,
  query,
  onClose,
  defaultElection,
}) {
  const [summary, setSummary] = useState(null);
  const [generating, setGenerating] = useState(false);
  const [data, setData] = useState(null);
  const [areFactsGrounded, setAreFactsGrounded] = useState(null);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [election, setElection] = useState(defaultElection);
  const [expanded, setExpanded] = useState(false);
  const ndJSONStreamReader = useRef(null);

  const onRequestExact = () => {
    setSummary(null);
    setErrorMsg(null);
    setError(null);
    setData(null);

    stream(true);

    amplitude.track(
      'Summary requested',
      {exact: true, election: election});
  };

  useEffect(() => {
    if (party && query) {
      setSummary(null);
      setErrorMsg(null);
      setError(null);
      setData(null);

      stream(false);

      amplitude.track(
        'Summary requested',
        {exact: false, election: election});
    }
  }, [party, query, election]);

  useEffect(() => {
    // Reset the election if the party or query changes
    setElection(defaultElection);
  }, [party, query]);

  const stream = async (exact) => {
    await ndJSONStreamReader.current?.cancel();
    setGenerating(false);
    const body = JSON.stringify(
      {
        query: query,
        party_uuid: party?.uuid,
        election_uuid: election?.uuid,
        exact: exact,
      });
    const response = await fetch(
      `${SUMMARIZER_URI}/statement`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: body,
      },
    );
    if (!response.ok) {
      // If we've errored, don't start listening on the stream and just exit after
      // setting an error message
      if (response.status == 503) {
        setErrorMsg(
          'Op dit moment is de samenvattings capaciteit van OpenVerkiezingen volledig bezet. ' +
          'Hierdoor kunnen we geen samenvatting voor je maken.',
        );
      } else {
        setErrorMsg('Op dit moment kunnen we geen samenvatting voor je maken.');
      }
      return;
    }

    ndJSONStreamReader.current = ndjsonStream(response.body).getReader();

    let result;
    setGenerating(true);
    while (!result || !result.done) {
      result = await ndJSONStreamReader.current.read();
      if (result?.value?.type == 'error') {
        // Not all errors will be http errors, cause some might occur during the stream.
        // Here we catch those
        setError(result?.value);
      } else if (result?.value?.type === 'answer') {
        const content = _.cloneDeep(result?.value?.content);
        setSummary((prev) => (prev || '') + content);
      } else if (result?.value?.type === 'context') {
        if (result?.value?.name === 'Rag[Paragraphs]') {
          const content = _.cloneDeep(result?.value?.content);
          setData((prev) => ({...prev, paragraphs: content}));
        } else if (result?.value?.name === 'Rag[FactsGrounded]') {
          const content = _.cloneDeep(result?.value?.content);
          setAreFactsGrounded(content);
        };
      };
    };

    setGenerating(false);
  };

  useEffect(() => {
    if (data) {
      amplitude.track(
        'Summary viewed',
        // { generating: !data?.summary, approximate: !!data?.approximate },
        {generating: generating, approximate: !!data?.approximate},
      );
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      if (error?.status == 429) {
        setErrorMsg(
          'Dat zijn iets te veel verzoeken in een korte tijd! ' +
          'Probeer het over een paar seconden nog eens.',
        );
      } else if (error?.status == 503) {
        setErrorMsg(
          'Momenteel is alle capaciteit van OpenVerkiezingen.nl volledig bezet. ' +
          'Probeer het over enige tijd nog eens. Je kunt wel een van de standaard stellingen ' +
          'boven het vrije invoerveld proberen.',
        );
      } else if (error?.status == 404) {
        setErrorMsg(
          'Het lijkt erop dat we voor deze verkiezingen nog geen programma van deze ' +
          'partij in onze database hebben.',
        );
      } else {
        setErrorMsg('Sorry, er is een onbekende fout opgetreden');
      }
    } else {
      setErrorMsg(null);
    }
  }, [error]);

  const pending = !summary && !errorMsg;

  const handleExpansion = (panel) => {
    if (!(panel === expanded)) {
      amplitude.track(
        'Source panel clicked.',
        {panelID: panel},
      );
    }
    setExpanded(panel === expanded ? false : panel);
  };

  return (<Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
        marginBottom: '15px',
        position: 'relative',
      }}>
      <img
        src={party?.logo}
        style={{maxWidth: '100px', maxHeight: '100px'}} />
      <IconButton
        sx={{position: 'absolute', top: '0px', right: '0px'}}
        onClick={onClose}>
        <CloseIcon /></IconButton>
      <ShareButton
        generating={generating}
        summary={summary}
        party={party}
        query={query}></ShareButton>
    </Box>

    <Box sx={{backgroundColor: (t) => t.palette.background.grey, p: 3}}>
      <InformedPendingHandler pending={pending} party={party} paragraphs={data?.paragraphs} />
      {(summary || errorMsg) &&
        <>
          {errorMsg && <Alert severity="error">
            {errorMsg}
          </Alert>}
          {data?.approximate && <RelatedStatementInfoDialog
            relatedStatement={data?.statement.statement}
            originalStatement={query}
            onRequestExact={onRequestExact}
          />}
          <DefaultSummary
            sx={{
              mt: 3,
              mb: 1,
            }}
            data={data}
            summary={summary}
            generating={generating}
            handleExpansion={handleExpansion}
            expanded={expanded}
            party={party}
            election={election}
            setElection={setElection}
          ></DefaultSummary>
          {!pending && <UsingAIWarning
            areFactsGrounded={areFactsGrounded}
            generating={generating}
            summary={summary}
            party={party}
            paragraphs={data?.paragraphs}
            sx={{mt: 3}} />}
        </>
      }
    </Box>
  </Box>);
}
