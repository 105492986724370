import {useEffect, useRef, useState} from 'react';
import {useSearchParamsState, useFetchGetRef} from '../hooks';
import Box from '@mui/material/Box';
import {BASE_URI, MIN_STATEMENT_LENGTH} from '../globals';
import WidgetPartyButtons from '../components/WidgetPartyButtons';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import './bnrFonts.css';
import {useOutletContext, useParams} from 'react-router-dom';
import LogoChip from '../components/LogoChip';


export default function EmbeddedView() {
  const {election} = useOutletContext();
  const {region} = useParams();
  console.log('election', election);
  const [statement, setStatement] = useSearchParamsState('query', '');
  const firstRender = useRef(true);
  const [customize, setCustomize] = useState(false);
  const {refData: parties, setRefUrl: setPartiesUrl} = useFetchGetRef(null, []);

  useEffect(() => {
    if (election) {
      setPartiesUrl(`${BASE_URI}/election/${election.uuid}/party/`);
    }
  }, [election]);

  useEffect(() => {
    if (firstRender.current && !statement) {
      setCustomize(true);
    }

    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [statement]);

  const computeLink = (party) => {
    console.log('computeLink', party);
    return `http://openverkiezingen.nl/${region}` +
      `?parties=${party.uuid}&query=${statement}&bnr=true`;
  };

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
      <Box
        sx={{
          backgroundColor: '#F5F5F5',
          borderLeft: '5px solid #ffd200',
          width: '100%',
          height: '100%',
          position: 'relative',
        }}>
        <Box
          sx={{
            position: 'absolute',
            bottom: '0px',
            right: '5px',
            borderRadius: '10px',
            p: '3px',
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '20px',
          }}>
          <img src="/logo_green.svg" height={20} style={{display: 'block'}} />
          <LogoChip election={election} sx={{fontSize: 8}} />
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '100%',
            p: '10px',
            pb: '35px',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              width: '100%',
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
              }}>
              <img
                src="/bnrElements/bnrArrow.svg"
                style={{marginTop: '5px', marginRight: '15px'}} />
              <Typography
                sx={{
                  fontSize: '0.875rem',
                  fontFamily: 'Plus Jakarta Sans, Helvetica',
                  fontWeight: 'bold',
                  mt: '15px',
                  color: '#000000',
                }}>Wat vinden de partijen van...</Typography>

            </Box>

            {statement && !customize && <Typography
              sx={{
                fontSize: '1.125rem',
                color: '#000000',
                fontFamily: 'Plus Jakarta Sans, Helvetica',
                fontWeight: '700',
              }}>{statement}</Typography>}

            {(customize || !statement) && <TextField
              fullWidth
              onChange={(e) => setStatement(e.target.value)}
              id="standard-basic"
              sx={{
                mb: 2,
                mt: '-5px',
              }}
              label="Typ hier jouw stelling..."
              value={statement}
              multiline
              maxRows={2}
              variant="standard"
            />}
          </Box>


          <Box
            sx={{'flexGrow': 1, 'width': '100%', 'position': 'relative'}}>
            <WidgetPartyButtons
              parties={parties}
              disabled={statement?.length < MIN_STATEMENT_LENGTH}
              computeLink={computeLink}
              linkProps={{target: '_blank'}}
              sx={{width: '100%', height: '100%'}}
              defaultElection={election}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
