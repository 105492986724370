import * as React from 'react';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';


export default function ElectionSelector({availableElections, election, setElection}) {
  return <ToggleButtonGroup
    color="primary"
    value={election?.uuid}
    exclusive
    size="small"
    onChange={(e, id) => setElection(availableElections.find((el) => el.uuid === id))}
  >
    {availableElections?.map((e) => (
      <ToggleButton
        disabled={e.uuid === election?.uuid}
        key={e.uuid}
        value={e.uuid}
        sx={{lineHeight: '1'}}>{e.slug}</ToggleButton>
    ))}
  </ToggleButtonGroup>;
}
