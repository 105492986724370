import Typography from '@mui/material/Typography';
import {TypeAnimation} from 'react-type-animation';
import Box from '@mui/material/Box';
import LogoChip from './LogoChip';


const Header = ({
  sx,
  election,
}) => (
  <Box
    textAlign="center"
    justifyContent="center"
    alignItems="center"
    sx={sx}
  >
    <Box sx={{position: 'relative', maxWidth: '900px', margin: 'auto'}}>
      <img src="/logo_white.svg" style={{width: '100%'}} />
      <LogoChip
        election={election}
        sx={{
          position: 'absolute',
          marginTop: '-0.5%',
          right: '2.2%',
          height: '20%',
          zIndex: 5,
          fontSize: 'min(1.5cqi, 2cqb)',
        }}

      />
    </Box>
    <Typography
      variant="h4"
      component="h4"
      color="text.white"
      sx={{
        fontSize: {
          sm: 30,
        },
      }}
      gutterBottom>
      <TypeAnimation
        sequence={[
          'Verkiezingsprogramma\'s voor iedereen',
          1000,
          'Volledig transparant door gebruik van AI',
          1000,
          'Zelf bepalen wat jij belangrijk vindt',
          1000,
          'Kies jouw partijen hieronder en voer een stelling in om te zien wat zij vinden',
        ]}
        element={'div'}
        cursor={true}
        repeat={0}
      />
    </Typography>
  </Box >
);

export default Header;
