import {useEffect} from 'react';
import Box from '@mui/material/Box';
import {Chip} from '@mui/material';
import * as React from 'react';
import {useFetchGetRef} from '../hooks';
import {BASE_URI} from '../globals';

export default function Demos({
  onSelect,
  disabled,
  election,
  sx,
}) {
  const {refData: demos, setRefUrl: setDemosUrl} = useFetchGetRef(null, []);
  useEffect(() => {
    if (election) {
      setDemosUrl(`${BASE_URI}/election/${election.uuid}/demo/`);
    }
  }, [election]);
  return <Box
    textAlign="center"
    justifyContent="center"
    alignItems="center"
    display="flex">
    <Box
      sx={{
        ...{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
        }, ...sx,
      }}>
      {demos?.map((demo, i) => (
        <Chip
          key={i}
          label={demo.statement.statement}
          color="primary"
          variant="outlined"
          disabled={disabled}
          onClick={() => {
            onSelect(demo);
          }}
        />
      ))}
    </Box>
  </Box>;
}
