import * as React from 'react';
import {useEffect, useState} from 'react';
import PendingHandler from './PendingHandler';
import Box from '@mui/material/Box';

export default function InformedPendingHandler({pending, party, paragraphs}) {
  const [currentlyProcessing, setCurrentlyProcessing] = useState(null);

  useEffect(() => {
    if (!paragraphs) {
      return;
    }

    let i = 0;
    const timer = setInterval(() => {
      setCurrentlyProcessing(paragraphs[i]);
      if (i + 1 < paragraphs.length) {
        i = i + 1;
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [paragraphs]);

  return <PendingHandler pending={pending}>
    {!currentlyProcessing && <Box>
      We scannen nu het programma van {party?.slug}.
    </Box>}
    {currentlyProcessing && <Box>
      {currentlyProcessing.title && <>
        We lezen nu de paragraaf <b>{currentlyProcessing.title}</b> uit het
        hoofdstuk <b>{currentlyProcessing.chapter}</b>.
      </>}

      {!currentlyProcessing.title && <>
        We lezen nu het hoofdstuk <b>{currentlyProcessing.chapter}</b>.
      </>}
    </Box>}
  </PendingHandler>;
}
