export const shareSummary = (statement, summary, party, location) => {
  navigator.share({
    title: `Wist jij dit van ${party.slug}?`,
    text: `Wist jij dat ${party.slug} dit ` +
      `vindt van de stelling "${statement}":

${summary}`,
    url: 'https://openverkiezingen.nl',
  });
};
